<template>
  <div class="container solingen-buttons container--fluid">
    <!--  :style="`background: url(${background})`" -->
    <div class="inner-container">
      <v-btn style="margin-left: 11.2rem; z-index: 10;">
        STAY AT HOME!
      </v-btn>
      <v-btn style="margin-left: 8rem; margin-top: -3px; z-index: 9;">
        RESTA A CASA!
      </v-btn>
      <v-btn x-large style="z-index: 8; margin-top: -3px;">
        MENSCH, BLEIB ZUHAUSE!
      </v-btn>
      <v-btn style="z-index: 7; margin-left: 8rem; margin-top: -3px;">
        RESTEZ À LA MAISON!
      </v-btn>
      <v-btn large style="z-index: 6; margin-left: 4rem; margin-top: -3px;">
        EVDE KALIN!
      </v-btn>
      <v-btn style="z-index: 5; margin-left: 20rem; margin-top: -3px;">
        ’ABAQ FI ALMANZIL!
      </v-btn>
    </div>
    <!--     <div class="inner-container">
      <v-btn x-large style="z-index: 8; margin-top: -3px;">
        MENSCH!
      </v-btn>
      <v-btn style="z-index: 7; margin-left: 2rem; margin-top: -3px;">
        BLEIB ZUHAUSE!
      </v-btn>
    </div> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    background: require('./dust_scratches.png')
  })
}
</script>

<style lang="sass" scoped>
  @import url('https://fonts.googleapis.com/css?family=Roboto:900&display=swap')
  .container.solingen-buttons
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    .inner-container
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start
      .v-btn
        height: auto
        width: auto
        padding: 0.15rem 0.6rem
        font-size: 3rem
        border-radius: 0.1rem
        font-weight: 900
        letter-spacing: 0.05rem
        background-color: #FED501!important
        box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.75) !important
        &.v-size--large
          font-size: 3.4rem
        &.v-size--x-large
          font-size: 4.1rem
</style>
